// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
	authentication: {
		issuer: "https://iam.blockco2.it/realms/blockco2",
		silentRefreshRedirectUri: "/assets/authentication/silent-refresh.html",
		clientId: "posti-bo",
		responseType: "code",
		scope: "openid profile offline_access roles phone address",
		redirectUri: null,
		postLogoutRedirectUri: null
	},
	services: {
		updatePasswordUrl: "https://iam.blockco2.it/admin/realms/blockco2/users/{id}/reset-password",
		blockChainService: "https://api-gateway.blockco2.it/blockchain-service",
		newBlockchainBasePath: "https://api-gateway.blockco2.it/blockchain-service",

		mediaContentsBasePath:	"https://api-gateway.blockco2.it/api-refactoring/api/v1/bo/images",
		getMediaContentsBasePath:	"https://api-gateway.blockco2.it/lp-images",
		restaurantService: "https://api-gateway.blockco2.it/api-refactoring/api/v1/bo/Restaurant",

		apiBasePath: "https://api-gateway.blockco2.it/api-refactoring/api/v1/bo",

		delegationUrl: "https://verifier.blockco2.it/delegate",
		certDataBaseUrl: "https://verifier.blockco2.it/json/{{companyId}}/{{certId}}.json",
		certVerifierUrl: "https://verifier.blockco2.it/{companyId}/{certificateId}/verify",
		dataLakeUrl: null
	},
	certificationConfig: {

		
	},
	domainConfig: {
		defaultCertPagePatternURL: "https://verifier.blockco2.it/{companyId}/{id}/verify",
		defaultProductLandingPagePatternURL: "https://certificate-lp.blockco2.it/{productId}",
		defaultEntityLandingPagePatternURL: "https://certificate-lp.blockco2.it/{productId}?id={id}",
		companyLogo: "assets/img/logos/Logo-type-trans-dark-blockco2.png",
		portalName: "blockco2",
		portalTitle: "blockco2",
		landingPageSiteLogo: "assets/img/logos/Logo-type-trans-dark-blockco2.png",

		allowExternalProviders: false,
		emailContact: "info@blockco2.it",
		showGuideButton: false,
		showSupportButton: false,
		guideButtonUrl: "",
		supportButtonUrl: ""
	},
	certificatePageBaseUrl: "https://certificate-lp.blockco2.it/landing",
	landingPageBaseUrl: "https://certificate-lp.blockco2.it/landing-v2",
	startLoginAtStartup: true,
	appRoutes: [
		{
			path: '',
			redirectTo: "user-profile",
			pathMatch: 'full',
		},
		{
			path: 'landing',
		},
		{
			path: '**',
			redirectTo: 'pages/error'
		},
		{
			path: 'unautorized-page',
		}
	]
};
