import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleQRCodeComponent } from 'app/common/components/modals/simple-qrcode.component';
import { BaseListTablePage } from 'app/common/components/pages/base-list-table.page';
import { DataTableAction, DataTableColumn, ListTableManager } from 'app/common/components/table/types';
import { ModalOptions } from 'app/common/models/models';
import { ModalService } from 'app/common/services/modal.service';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { iconMoreUsers } from 'app/common/utils/icons.utils';
import { Company, EntityDefinition, FieldType, Product } from 'app/models/entities.model';
import { AddACRToEntityModal, EntityDescription } from 'app/pages/admin/acr-components/add-acr-to-entity.modal';
import { CompanyService } from 'app/services/company.service';
import { EntityService } from 'app/services/entity.service';
import { ProductService } from 'app/services/product.service';
import { URLUtils } from 'app/services/url-utils';
import { Observable } from 'rxjs';
import { ModalEntityList } from '../modal-entities-list/entities-list.modal';
import * as XLSX from 'xlsx';
import { ModalExcelFileReader } from '../modal-excel-file-reader/excel-file-reader.modal';

import { forkJoin } from 'rxjs';
import { AddEntityModalForm } from 'app/pages/company/add-entity-modal/add-entity-modal.page';
import { ProfileService } from 'app/services/profile.service';
import { Roles, UserProfile } from 'app/models/profile.models';
import { ACRService } from 'app/services/acl.service';


@Component({
    selector: 'entity-list-page',
    templateUrl: './entity-list.page.html'
})
export class EntityListPage extends BaseListTablePage<any> {
	currentDomain;
	domainPath;
	downloadExcelEnabled = false;
	deleteEntityEnabled = false;
	numberOfAvailableItems: number = 0;
	dataLoaded = false;
    public tableColumns: DataTableColumn[] = [];
    public tableActions: DataTableAction[] = [];
    public filters: Filters = new Filters();

    constructor(
		_activatedRoute: ActivatedRoute,
		private _router: Router,
		private _modalService: ModalService,
		private _productService: ProductService,
		private _entityService: EntityService,
		private _companyService: CompanyService,
		private _profileService: ProfileService,
		private _acrService: ACRService
		) {
        super(_activatedRoute, false);
    }

	public actions = () => {
		// if (this.currentDomain && domainConfig[this.currentDomain]) {
		// 	return domainConfig[this.currentDomain].entityDefinition;
		// }
	}

	private path = (obj,path) => {
        try{
            return eval("obj."+path);
        } catch(e) {
            return undefined;
        }
    }
	private productDefinition: Product;
	private referredEntity: EntityDefinition;
	private company: Company;
	private userProfile: UserProfile;
	private companyId;
	private fieldToShow = [];
	
	isOwner: boolean = false;

	initialize = (): void => {
		this.on(this.activatedRoute.paramMap.subscribe(queryParams => {
			console.log("queryParams",queryParams);
			this.companyId = queryParams.get("companyId");

			this._profileService.getLoggedProfile().subscribe((userProfile) => {
				this.userProfile = userProfile;
				let toCall: any = {
				}
				
				toCall["product"] = this._productService.getByField( queryParams.get("companyId"), "productId", queryParams.get("productId"));
				toCall["company"] = this._companyService.getCompanyByField("companyId", queryParams.get("companyId"));
				toCall["acr"] = this._acrService.getMyCompanyACR(this.userProfile)

				forkJoin(toCall).subscribe((data) => {
					this.productDefinition = data["product"];
					this.company = data["company"];
					let acr = data["acr"];
					if (acr && acr.length > 0) {
						acr.forEach((companyAcr) => {
							if (companyAcr.entityDomain ==  queryParams.get("companyId")) {
								this.isOwner = true;
							}
						})
					}
					this.productDefinition.linkedEntity.forEach((entity) => {
						if (entity.entityId == queryParams.get("entityType")) {
							this.referredEntity = entity;
						}
					})
					
					this.createColumms();
					this.createActions();
					this.dataLoaded = true;
					this.dataTableManager.startReload();
				});
			})

			

		}));
    }

	get productName() {
		if (this.productDefinition) {
			return this.productDefinition.name;
		}
	}
	get entityName() {
		if (this.referredEntity) return this.referredEntity.name;
		return "";
	}
   res;

	protected getReturnQueryParams = (baseUrl: string = null): any => {
		const ret = baseUrl || window.location.pathname;
		const urlParams = new URLSearchParams(window.location.search);

		let additionalParams: any = {};
		urlParams.forEach((value, key) => {
			additionalParams["" + key] = value;
		})

		return additionalParams;
	}
   goBack = () => {
		// this.on(this.activatedRoute.paramMap.subscribe(queryParams => {
		// 	this._router.navigate(["/company", queryParams.get("companyId")], { queryParams: {returnUrl:  "/user-profile"} });
		// }))

		this.activatedRoute.queryParams.subscribe((queryParam) => {
			if (queryParam["returnUrl"]) 
				this._router.navigateByUrl(queryParam["returnUrl"]);
			else {
				this.on(this.activatedRoute.paramMap.subscribe(queryParams => {
					this._router.navigate(["/company", queryParams.get("companyId")], { queryParams: this.getReturnQueryParams() });
				}))
			}

		})
   }
   

	private getEntities = (): Observable<any[]> => {
		return new Observable(observer => {
			this.on(this.activatedRoute.paramMap.subscribe(queryParams => {
				this.fieldToShow = ["id"];
				this.referredEntity.groups.forEach((g) => {
					g.fields.forEach((f) => {
						if (!f.hidden) {
							this.fieldToShow.push(g.groupLabel+"."+f.fieldLabel);
						}
					})
				})
				return this._entityService.getAll(queryParams.get("companyId"),  queryParams.get("productId"), queryParams.get("entityType"), this.fieldToShow).subscribe((result) => {
					let toShow: any[] = [];
					this.numberOfAvailableItems = result.length;
					result.forEach((element) => {
						let newElement = new Object();
						Object.assign(newElement, element);
						for (let key in element) {
							if (key != 'lastUpdateDate' && key != 'domains' && key != 'id') {
								for (let innerKey in element[key]) {
									newElement[innerKey] = element[key][innerKey];
								}
							}
						}
						toShow.push(newElement);
					})
					observer.next(toShow);
				})
			}))
		});
		
	}

    protected getDataTableManager = (): ListTableManager<any> => {
        return new ListTableManager(this.getEntities)
    }

    private createColumms = (): void => {
		let filterKeys = [];
		this.referredEntity.groups.forEach((group) => {
			if (!group.isArray) {
				group.fields.forEach((field) => {
					if (!field.hidden) {
						switch (field.fieldType) {
							case FieldType.DATE:
								this.tableColumns.push(DataTableUtils.createDateColumn(field.fieldLabel, field.fieldLabel, false));
								break;
							// case FieldType.DATETIME:
							// 	this.tableColumns.push(DataTableUtils.createDateTimeColumn(field.fieldLabel, field.fieldLabel, false));
							// 	break;
							case FieldType.DECIMAL:
							case FieldType.INTEGER:
								this.tableColumns.push(DataTableUtils.createNumberColumn(field.fieldLabel, field.fieldLabel, (field.fieldType == FieldType.DECIMAL)? 2:0, false))
								break;
							case FieldType.DESCRIPTION:
							case FieldType.SINGLE_SELECT:
							case FieldType.STRING:
								this.tableColumns.push(DataTableUtils.createStringColumn(field.fieldLabel, field.fieldLabel, false));
								break;
							case FieldType.MULTIPLE_SELECT:
								this.tableColumns.push(DataTableUtils.createArrayColumn(field.fieldLabel, field.fieldLabel, null, false));
								break;
							default:
								break;
						}						
						filterKeys.push(field.fieldLabel);
					}
				})
			}
			if (this.tableColumns.length == 0) {
				this.tableColumns.push(DataTableUtils.createStringColumn("id", "Identificativo", false));
				filterKeys.push("id");

			}

			if (filterKeys.length > 0) this.filterKeys = filterKeys;

		})
				

	
		// domainConfig[domain].fields.forEach(element => {
		// 	if (!element.hidden)
		// 	if (element.filter)
		// 		filterKeys.push(element.id);
		// });
		// if (filterKeys.length > 0) this.filterKeys = filterKeys;
    }
	get isAdmin() {
		// return this._profileService.isInRole(Roles.ADMIN);
		return this.isSuperAdmin;
	}
	get isSuperAdmin() {
		return this._profileService.isInRole(Roles.SUPER_ADMIN) || this._profileService.isInRole(Roles.ADMIN);
	}
    private createActions = (): void => {
        let button = new DataTableAction();
        button.funcToInvoke = this.edit;
        button.label = "Aggiorna dati";
        button.icon = "ft-edit";
        button.enableFunc =  (row) => {return !row.certified && (this.isAdmin || this.isOwner || this.isSuperAdmin)};
		this.tableActions.push(button);

		button = new DataTableAction();
		button.funcToInvoke = this.delete;
		button.label = "Elimina";
		button.icon = "ft-trash-2";
		button.enableFunc =  (row) => {return this.isAdmin || this.isOwner || this.isSuperAdmin};
		this.tableActions.push(button);
		

		// if (domainConfig[domain].showBlockChainUrl) {
		button = new DataTableAction();
		button.funcToInvoke = this.showBlockchainCert;
		button.label = "Visualizza certificato in blockchain";
		button.icon = "fas fa-shield-alt";
		button.enableFunc =  (row) => {return row.certified};
		this.tableActions.push(button);
		// }


		if (this.referredEntity && this.referredEntity.landingPageUrl) {
			button = new DataTableAction();
			button.funcToInvoke = this.showLandigPage;
			button.label = "Visualizza Landing Page";
			button.icon = "fas fa-link";
			button.enableFunc =  (row) => {return row.certified}
			this.tableActions.push(button);

			button = new DataTableAction();
			button.funcToInvoke = this.showCode;
			button.label = "Visualizza QR-Code Landing Page";
			button.icon = "fas fa-qrcode";
			button.enableFunc =  (row) => {return row.certified}
			this.tableActions.push(button);
		}
		else {
			button = new DataTableAction();
			button.funcToInvoke = this.showCertQRCode;
			button.label = "Visualizza QR-Code Pagina Certificato";
			button.icon = "fas fa-qrcode";
			button.enableFunc =  (row) => {return row.certified}
			this.tableActions.push(button);
		}
		
	


		// button = new DataTableAction();
		// button.funcToInvoke = this.showNftList;
		// button.label = "Visualizza NFT Associati";
		// button.icon = iconElementView;
		// // button.enableFunc =  (row) => {return row.}
		// this.tableActions.push(button);
	
		console.log("this company", this.company);
		
		if (this.companyId == "aziendaagricolaprova4") {
			button = new DataTableAction();
			button.funcToInvoke = this.openClaimPage;
			button.label = "Apri link per riscatto";
			button.icon = iconMoreUsers;
			// button.enableFunc =  (row) => {return row.}
			this.tableActions.push(button);
		}
		
	

		
		// if (domainConfig[domain].landingPageUrl) {
		// 	button = new DataTableAction();
		// 	button.funcToInvoke = this.showLandigPage;
		// 	button.label = "Visualizza Landing Page";
		// 	button.icon = "fas fa-link";
		// 	this.tableActions.push(button);
		// }
		// if (this._profileService.isInRole(Roles.ADMIN)) {
		// 	button = new DataTableAction();
		// 	button.funcToInvoke = this.addACR;
		// 	button.label = "Utenti con accesso";
		// 	button.icon = "fas fa-user-shield";
		// 	this.tableActions.push(button);
		// }
    }
	public openClaimPage = (row: any): void => {
		if (this.companyId) {
			let url = "/user-profile?domain=aziendaagricolaprova4&productId=nuovoprodottoditest&cert_id="+row.id;
			window.open(url, "_blank");
		}
	}

	public addACR = (row: any): void => {
		// let modalOption: ModalOptions<boolean> = {
		// 	size: 'xl',
		// 	callback: (res) => {}
		// }
		// let data: EntityDescription = {
		// 	id: row.id,
		// 	entityName: row.sign,
		// 	entityDomain: domainConfig[this.currentDomain].domainId
		// }
		// this._modalService.show(AddACRToEntityModal, data, modalOption);
	}
	public showBlockchainCert = (row: any): void => {
		if (this.company) {
			let url = URLUtils.getCertPageURL(this.company,this.productDefinition, this.referredEntity, row);
			window.open(url, "_blank");
		}
		else {
			this._companyService.getCompanyByField("companyId",this.companyId).subscribe((result) => {
				this.company = result;
				let url = URLUtils.getCertPageURL(this.company,this.productDefinition,this.referredEntity, row);
				window.open(url, "_blank");
			})
		}
	}
	public showLandigPage = (row: any): void => {
		if (this.company) {
			let url = URLUtils.getEntityLandingPageURL(this.company,this.productDefinition, this.referredEntity, row);
			window.open(url, "_blank");
		}
		else {
			this._companyService.getCompanyByField("companyId",this.companyId).subscribe((result) => {
				this.company = result;
				let url = URLUtils.getEntityLandingPageURL(this.company,this.productDefinition, this.referredEntity, row);
				window.open(url, "_blank");
			})
		}


	}
    public edit = (row: any): void => {
		// this.on(this.activatedRoute.paramMap.subscribe((queryParams) => {
		// 	console.log("queryParams",queryParams);
		// 	const qp = {};
		// 	qp['returnUrl'] = this.getReturnUrl();
		// 	this._router.navigate(["entities-list", queryParams.get("companyId"), queryParams.get("productId"), queryParams.get("entityType"), "edit", row.id], { queryParams: qp });
		// }));

		
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {
				console.log("reloading...");
				
				this.dataTableManager.startReload();
			}
		}
		let data = {
			product: this.productDefinition,
			entity: this.referredEntity,
			company: this.company,
			userProfile: this.userProfile,
			entityData: row,
			readonlyFieldName: true
		}
		this._modalService.show(AddEntityModalForm, data, modalOption);
    }


	public delete = (row: any): void => {
		let callback = (data) => {
			if (data) {
				this.on(this.activatedRoute.paramMap.subscribe(qp => {
					this._entityService.delete(this.companyId,this.productDefinition.productId,this.referredEntity.entityId ,row.id).subscribe((result) => {
						this.dataTableManager.startReload();
					})
				}));
			}
		}
		this._modalService.showConfirm("common.dialogs.alertRemoveItemTitle", "common.dialogs.alertRemoveItemMessage", {
			callback: callback,
			size: "md"
		});
    }
	public addEntity = (entityType?: string) => {
		this.on(this.activatedRoute.paramMap.subscribe((queryParams) => {
			console.log("queryParams",queryParams);
			const qp = {};
			qp['returnUrl'] = this.getReturnUrl();
			this._router.navigate(["entities-list", queryParams.get("companyId"), queryParams.get("productId"), queryParams.get("entityType"), "edit"], { queryParams: qp });
		}));
	}

	public downloadCSV = () => {
		// if (this.currentDomain) {
		// 	let url = environment.services.restaurantService + "/export/csv/" + this.currentDomain ;
		// 	window.open(url, "_blank");			
		// }

		

	}
	openFipeExcel() {
		// window.open("https://docs.google.com/spreadsheets/d/1PsHEbXq6vvA9DJEV6mLRTFppkjRmB6nG/edit?usp=sharing&ouid=107316743256498418483&rtpof=true&sd=true", "_blank");
		
	}
	public downloadExcel = () => {
		
		// if (this.currentDomain) {
		// 	let url = environment.services.restaurantService + "/export/download/excel/" + this.currentDomain ;
		// 	window.open(url, "_blank");			
		// }

	}

	
	showCode = (row: any) => {

		let modalOption: ModalOptions<boolean> = {
			size: 'md',
			callback: (data) => {}
		}
		let title = "QRcode landing page di " + this.referredEntity.name;
		
		if (this.company) {
			let url = URLUtils.getEntityLandingPageURL(this.company,this.productDefinition, this.referredEntity, row);
			this._modalService.show(SimpleQRCodeComponent, {url: url, title: title}, modalOption);
		}
		else {
			this._companyService.getCompanyByField("companyId",this.companyId).subscribe((result) => {
				this.company = result;
				let url = URLUtils.getEntityLandingPageURL(this.company,this.productDefinition, this.referredEntity, row);
				this._modalService.show(SimpleQRCodeComponent, {url: url, title: title}, modalOption);				
			})
		}
	}

	showCertQRCode = (row: any) => {
		let modalOption: ModalOptions<boolean> = {
			size: 'md',
			callback: (data) => {}
		}
		let title = "QRcode pagina di certificato di " + this.referredEntity.name;
		
		if (this.company) {
			let url = URLUtils.getCertPageURL(this.company,this.productDefinition, this.referredEntity, row);
			this._modalService.show(SimpleQRCodeComponent, {url: url, title: title}, modalOption);
		}
		else {
			this._companyService.getCompanyByField("companyId",this.companyId).subscribe((result) => {
				this.company = result;
				let url = URLUtils.getCertPageURL(this.company,this.productDefinition,this.referredEntity, row);
				this._modalService.show(SimpleQRCodeComponent, {url: url, title: title}, modalOption);				
			})
		}

		
	}


	uploadFromExcel = () => {
		this.on(this.activatedRoute.paramMap.subscribe((queryParams) => {
			let modalOption: ModalOptions<boolean> = {
				size: 'xl',
				backdrop: 'static',
				keyboard: false,
				callback: (res) => {
					if (res) {
						console.log("Starting reload");
						this.dataTableManager.startReload();

					}
				}
			}
			
			let data: {
				tableColumns: DataTableColumn[];
				companyId: string;
				productId: string;
				entityType: string;
			} = {
				tableColumns: this.tableColumns,
				companyId: queryParams.get("companyId"),
				entityType: queryParams.get("entityType"),
				productId: queryParams.get("productId")
			};
			this._modalService.show(ModalExcelFileReader, data, modalOption);
		}));

		
	}

	
	showNftList = (row) => {
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {}
		}
		
		let data = {
			entityId: row.id,
			entityDef: this.referredEntity,
			product: this.productDefinition,
			companyId: this.companyId
		}
		this._modalService.show(ModalEntityList, data, modalOption);
	}



	public createSheet = () => {
		let modalOption: ModalOptions<boolean> = {
			size: 'md',
			callback: (res) => {
				if (res) {
					var workbook = XLSX.utils.book_new();
					
					this.referredEntity.groups.forEach((group, index) => {
						if (!group.isArray) {
							let header: any = [];

							group.fields.forEach((field) => {
								header.push(""+field.fieldLabel);
								// Per trac ce sarà necessario aggiungere la label del gruppo
								// header.push(group.groupLabel +": "+field.fieldLabel);
							})
							let data = [];
							data.push(header);
							let sheet = XLSX.utils.aoa_to_sheet(data, {});
							XLSX.utils.book_append_sheet(workbook, sheet, "Foglio " + (index+1));
						}
					})
					
					XLSX.writeFile(workbook, "template.xls");
				}
				
			}
		}
		this._modalService.showConfirm("Compilazione del modello Excel","Con questa funzione otterrai un foglio excel che dovrai compilare per poter importare massivamente i dati che vuoi certificare. Nel compilare il foglio assicurati che le celle siano di tipo testo e che excel non modifichi automaticamente il tipo delle celle (es. quando digiti '01/01/2023' in una cella, excel converte la cella nel tipo data). Se Microsoft Excel modifica automaticamente il tipo di cella, la funzionalità di importazione massiva non potrà importare i dati correttamente. Vuoi continuare?", modalOption);
		
		
	}
}


class Filters {
}