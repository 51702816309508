import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { SelectListitem } from "app/common/models/models";
import { ToastService } from "app/common/services/toasts.service";
import { Company } from "app/models/entities.model";
import { Roles, UserProfile } from "app/models/profile.models";
import { ACR, ACRService } from "app/services/acl.service";
import { CompanyService } from "app/services/company.service";
import { ProfileService } from "app/services/profile.service";
import { forkJoin } from "rxjs";


@Component({
    selector: 'company-user-permission',
    templateUrl: './company-user-permission.component.html'
})
export class CompanyUserMappingComponent implements OnInit {
	@Input() company: Company;
	usersForm: FormGroup;
	usersList:  SelectListitem[] = [];
	originalACR: ACR[] = [];
	originalUserList: UserProfile[] = [];
	constructor(
		private _userProfileService: ProfileService,
		private _fb: FormBuilder,
		private _toastService: ToastService,
		private _acrService: ACRService) {

	}

	isOwner: boolean = false;

	get disableControl() {
		return !this.isOwner && !(this._userProfileService.isInRole(Roles.ADMIN) || this._userProfileService.isInRole(Roles.SUPER_ADMIN));
	}

	ngOnInit(): void {
		if (this.company) {
			forkJoin({
				acr: this._acrService.getCompanyACR(this.company.companyId),
				users: this._userProfileService.getAll(["id","userId","firstName","lastName","email"])
			}).subscribe((result) => {
				this.usersList = result.users.map((user) => { return new SelectListitem(user.userId, user.firstName + " " + user.lastName + " ("+user.email+")")});
				this.originalACR = result.acr;
				if (this.originalACR  && this.originalACR .length > 0) {
					this.originalACR .forEach((companyAcr) => {
						if (companyAcr.entityDomain ==  this.company.companyId) {
							this.isOwner = true;
						}
					})
				}

				this.originalUserList = result.users;
				this.usersForm = this._fb.group({
					enabledUsers: [result.acr.map(acr => {return acr.userId}), CommonValidators.arrayMinItemsValidator(1)]
				})
				
			})
		}
	

		// if (this._userProfileService.isInRole(Roles.ADMIN) || this._userProfileService.isInRole(Roles.SUPER_ADMIN)) {
		// 	if (this.company) {
		// 		forkJoin({
		// 			acr: this._acrService.getCompanyACR(this.company.companyId),
		// 			users: this._userProfileService.getAll(["id","userId","firstName","lastName","email"])
		// 		}).subscribe((result) => {
		// 			this.usersList = result.users.map((user) => { return new SelectListitem(user.userId, user.firstName + " " + user.lastName + " ("+user.email+")")});
		// 			this.originalACR = result.acr;
		// 			this.originalUserList = result.users;
		// 			this.usersForm = this._fb.group({
		// 				enabledUsers: [result.acr.map(acr => {return acr.userId}), CommonValidators.arrayMinItemsValidator(1)]
		// 			})
					
		// 		})
		// 	}
		// }
	}

	public save = () => {
		if (this.usersForm.value.enabledUsers.length > 0) {
			let toCall = [];
			this.originalACR.forEach((acr) => {
				toCall.push(this._acrService.deleteCompanyACR(acr))
			});
			this.usersForm.value.enabledUsers.forEach(userId => {
				let user = this.originalUserList.filter((profile) => { return profile.userId == userId}) [0];
				toCall.push(this._acrService.linkCompanyToUser(user,this.company,"ADMIN"))
			});

			forkJoin(toCall).subscribe((result) => {
				if (result) {
					this._toastService.showSuccess("Salvataggio avvenuto con successo");
				}
			})
		}
		
	}
}