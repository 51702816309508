import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseListTablePage } from 'app/common/components/pages/base-list-table.page';
import { DataTableAction, DataTableColumn, ListTableManager } from 'app/common/components/table/types';
import { ModalOptions } from 'app/common/models/models';
import { ModalService } from 'app/common/services/modal.service';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { Restaurant } from 'app/models/restaurant.model';
import { ProfileService } from 'app/services/profile.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Roles, UserProfile } from "../../../models/profile.models";
import { EditUserRoleModal } from '../edit-user/edit-user-roles.page';

@Component({
	selector: 'user-list-page',
	templateUrl: './user-list.page.html'
})
export class UserListPage extends BaseListTablePage<UserProfile> {
	currentDomain;
	domainPath;
	downloadExcelEnabled = false;
	deleteEntityEnabled = false;
	numberOfAvailableItems: number = 0;
	public tableColumns: DataTableColumn[] = [];
	public tableActions: DataTableAction[] = [];
	public filters: Filters = new Filters();
	constructor(_activatedRoute: ActivatedRoute, private _router: Router, private _titleService: Title, private _userProfileService: ProfileService, private _modalService: ModalService) {
		super(_activatedRoute, true);


	}

	private path = (obj, path) => {
		try {
			return eval("obj." + path);
		} catch (e) {
			return undefined;
		}
	}

	initialize = (): void => {
		this.createColumms();
		this.createActions();
		this._titleService.setTitle("Gestione utenze")
	}

	
	private getUsers = (): Observable<UserProfile[]> => {
		return this._userProfileService.getAll([
			"id",
			"userId",
			"firstName",
			"lastName",
			"email",
			"roles",
			"enabledDashboards"
		]).pipe(map((r) => {
			if (r) this.numberOfAvailableItems = r.length;
			return r.map((u) => {
				(<any>u).name=u.firstName + " " + u.lastName;
				u.roles = u.roles.filter((role) => {
					return role == Roles.DASHBOARD_USER ||
						role ==  Roles.ADMIN ||
						role == Roles.LP_EDITOR ||
						role == Roles.VIEWER_ADMIN
				})
				u.roles = u.roles.map((r) => {
					if (r == Roles.DASHBOARD_USER) return "Utente abilitato";
					if (r == Roles.ADMIN) return "Amministratore Piattaforma";
					if (r == Roles.LP_EDITOR) return "Editor Landing Page";
					if (r == Roles.VIEWER_ADMIN) return "Viewer info portale";
				})
				return u
			});}))

	}

	protected getDataTableManager = (): ListTableManager<UserProfile> => {
		return new ListTableManager(this.getUsers)
	}

	private createColumms = (): void => {
		let fields = [
			{id: "id", hidden: true},
			{id: "userId", dataTableField: DataTableUtils.createStringColumn("userId", "User ID", true), hidden: true},
			{id: "name", dataTableField: DataTableUtils.createStringColumn("name", "Nome", true), hidden: false},
			{id: "email", dataTableField: DataTableUtils.createStringColumn("email", "email", true), hidden: false},
			{id: "roles", dataTableField: DataTableUtils.createArrayColumn("roles", "ruoli", ""), hidden: false},
			{id: "enabledDashboards", dataTableField: DataTableUtils.createArrayColumn("enabledDashboards", "ulteriori privilegi", ""), hidden: false}
			
		];
		fields.forEach(element => {
			if (!element.hidden)
				this.tableColumns.push(element.dataTableField);
		});
	}

	private createActions = (): void => {
		let button = new DataTableAction();
		button.funcToInvoke = this.edit;
		button.label = "Aggiorna dati";
		button.icon = "ft-edit";
		this.tableActions.push(button);

		button = new DataTableAction();
		button.funcToInvoke = this.delete;
		button.label = "Elimina";
		button.icon = "ft-trash-2";
		this.tableActions.push(button);
	}
	
	public delete = (row: Restaurant): void => {
		let callback = (data) => {
			if (data) {
				this.on(this.activatedRoute.paramMap.subscribe(qp => {
					this._userProfileService.deleteProfile(row.id).subscribe((result) => {
						this.dataTableManager.startReload();
					})

				}));

				
			}
		}
		this._modalService.showConfirm("common.dialogs.alertRemoveItemTitle", "common.dialogs.alertRemoveItemMessage", {
			callback: callback,
			size: "md"
		});
    }
	
	private saveResult = (result): void => {
		if (result) {
			this.dataTableManager.startReload();
		}
	}
    public edit = (row: any): void => {
		
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: this.saveResult
		}
		this._modalService.show(EditUserRoleModal, row, modalOption);
    }
}


class Filters {
}